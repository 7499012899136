<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="text-h5 font-weight-medium mb-2">Наименование товара</div>
      </v-col>
    </v-row>

    <v-card elevation="0" rounded="lg" color="#474747" variant="flat" class="mt-3">
      <v-card  elevation="0" rounded="lg" class="rounded-b-0" variant="flat" color="#474747">
        <v-row class="pt-3 pb-3" align="center">
          <v-col cols="1"></v-col>
          <v-col cols="auto">Доступ в закрытый канал</v-col>
        </v-row>
      </v-card>
    </v-card>

    <v-card id="elementToHide" elevation="0" rounded="lg" color="#474747" variant="flat" class="mt-3 hidden">
      <v-card elevation="0" rounded="lg" class="rounded-b-0" variant="flat" color="#474747">
        <v-row class="pt-3 pb-3" align="center">
          <v-col cols="1"></v-col>
          <v-col cols="auto">
            <v-card width="25px" variant="tonal" height="25px" color="green" rounded="lg">
              <v-icon class="icon-pending" color="yellow">
                mdi-message-text
              </v-icon>
            </v-card>
          </v-col>
          <v-col cols="auto">Ежемесячное списание каждые 30 дн.</v-col>
        </v-row>
      </v-card>
    </v-card>

    <v-row>
      <v-col cols="12">
        <div class="text-h6 font-weight-medium mb-2">Ваш E-mail</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-text-field id="myEmail" rounded="lg" color="#474747" label="example@gmail.com"></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div class="text-h6 font-weight-medium mb-2">Период оплаты</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2">
        <v-btn id="month_1" class="btn active" @click="activatePeriodButton('month_1')" variant="tonal">1 мес</v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn id="month_3" class="btn" @click="activatePeriodButton('month_3')" variant="tonal">3 мес</v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn id="month_6" class="btn" @click="activatePeriodButton('month_6')" variant="tonal">6 мес</v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn id="month_12" class="btn" @click="activatePeriodButton('month_12')" variant="tonal">1 год</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div class="text-h6 font-weight-medium mb-2">Метод оплаты</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1">
        <v-btn id="ru" class="btn active" @click="activateBankButton('ru')" variant="tonal">Банк РФ</v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn id="eu" class="btn" @click="activateBankButton('eu')" variant="tonal">Иностранный банк</v-btn>
      </v-col>
    </v-row>

    <v-row id="CurrencyToHide" class="pt-3 pb-3 hidden" align="center">
      <v-col cols="12">
        <div class="text-h6 font-weight-medium mb-2">Валюта</div>
      </v-col>
      <v-row>
      <v-col cols="1">
        <v-btn id="usd" class="btn active" @click="activateCurrencyButton('usd')" variant="tonal">USD</v-btn>
      </v-col>
      <v-col cols="1">
        <v-btn id="eur" class="btn" @click="activateCurrencyButton('eur')" variant="tonal">EUR</v-btn>
      </v-col>
    </v-row>
    </v-row>



    <v-row>
      <v-col cols="12">
        <div class="text-h6 font-weight-medium mb-2">Стоимость</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div id="displayCost" class="text-h6 font-weight-medium mb-2">1600 RUB</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1">
        <v-btn @click="confirmButton()" block rounded="lg">Оплата</v-btn>
      </v-col>
    </v-row>


  </v-container>
</template>






















<script>
import axios from "axios";

var price;
var currency = 'RUB';
var region;
var period;
var cur;
var email;

var product = {
      'ru': {
        'usd': {
          'month_1': '1600',
          'month_3': '4300',
          'month_6': '8000',
          'month_12': '9800',
          'currency': 'RUB'
        },
        'eur': {
          'month_1': '1600',
          'month_3': '4300',
          'month_6': '8000',
          'month_12': '9800',
          'currency': 'RUB'
        },
      },
      'eu': {
        'usd': {
          'month_1': '18',
          'month_3': '48',
          'month_6': '88',
          'month_12': '108',
          'currency': 'USD'
        },
        'eur': {
          'month_1': '16',
          'month_3': '43',
          'month_6': '79',
          'month_12': '97',
          'currency': 'EUR'
        }
      }
    };

export default {

  data: () => ({
    name: "TransferPage",
    showContact: false,
    recipient: "Мария",
    isActive: true
  }),


  mounted() {
    window.Telegram.WebApp.setBackgroundColor("#000000")
    window.Telegram.WebApp.onEvent('backButtonClicked', () => {
      this.$router.push({ path: '/'});
    })
    window.Telegram.WebApp.BackButton.show()
    this.checkInitData({_auth: window.Telegram.WebApp.initData})
  },
    unmounted() {
      window.Telegram.WebApp.BackButton.hide()
  },

  methods: {
    checkInitData(data) {
      axios.post('api/checkInitData', data)
          .then(response => {
            this.user_id = response.data.user.id
          })
          .catch(error => {
            console.error(error);
          });
    },
    confirmButton() {
      const inputElement = document.getElementById('myEmail');
      email = inputElement.value;
      if (email == "") {
        alert('Укажите электронную почту');
      } else {
        axios.post('api/createPayment', {'act': 'gift', 'cost': Number(price), 'age': period, 'user_id': this.user_id, 'email': email}) // 624152144 this.user_id
            .then(response => {
              //alert(JSON.stringify(response.data));
              this.$router.push(response.data.url);// /transfer?id='+response.data.id'
            })
            .catch(error => {
              alert(JSON.stringify(error));
              //console.error(error);
            });
      }
    },

    // стили моего шаблона
    activatePeriodButton(activeButtonId) {
      // Получаем кнопки по id
      const button1 = document.getElementById('month_1');
      const button2 = document.getElementById('month_3');
      const button3 = document.getElementById('month_6');
      const button4 = document.getElementById('month_12');

      // Убираем класс active у всех кнопок
      button1.classList.remove('active');
      button2.classList.remove('active');
      button3.classList.remove('active');
      button4.classList.remove('active');

      // Добавляем класс active для нажатой кнопки
      document.getElementById(activeButtonId).classList.add('active');



      // Находим все элементы с классом 'active'
      const activeElements = document.querySelectorAll('.active');
      // Собираем их id
      const activeIds = Array.from(activeElements).map(element => element.id);
      //alert(activeIds[0]);
      region = activeIds[1];
      period = activeIds[0];
      cur = activeIds[2];
      price = product[region][cur][period];
      currency = product[region][cur]["currency"];

      const displayText = document.getElementById("displayCost");
      displayText.textContent = price + ' ' + currency;
    },

    activateBankButton(activeButtonId) {
      // Получаем кнопки по id
      const button1 = document.getElementById('ru');
      const button2 = document.getElementById('eu');
      const elementToHide = document.getElementById("CurrencyToHide");

      // Убираем класс active у всех кнопок
      button1.classList.remove('active');
      button2.classList.remove('active');

      // Добавляем класс active для нажатой кнопки
      document.getElementById(activeButtonId).classList.add('active');

      if (button1.classList.contains("active")) {
          // Если элемент имеет класс active, скрыть другой элемент
          elementToHide.classList.add("hidden");
      } else {
          // Показать элемент, если класса active нет
          elementToHide.classList.remove("hidden");
      }



      // [ ]Находим все элементы с классом 'active'
      const activeElements = document.querySelectorAll('.active');
      // Собираем их id
      const activeIds = Array.from(activeElements).map(element => element.id);
      //alert(activeIds[0]);
      region = activeIds[1];
      period = activeIds[0];
      cur = activeIds[2];
      price = product[region][cur][period];
      currency = product[region][cur]["currency"];

      const displayText = document.getElementById("displayCost");
      displayText.textContent = price + ' ' + currency;

    },

    activateCurrencyButton(activeButtonId) {
      // Получаем кнопки по id
      const button1 = document.getElementById('usd');
      const button2 = document.getElementById('eur');

      // Убираем класс active у всех кнопок
      button1.classList.remove('active');
      button2.classList.remove('active');

      // Добавляем класс active для нажатой кнопки
      document.getElementById(activeButtonId).classList.add('active');



      // [ ]Находим все элементы с классом 'active'
      const activeElements = document.querySelectorAll('.active');
      // Собираем их id
      const activeIds = Array.from(activeElements).map(element => element.id);
      //alert(activeIds[0]);
      region = activeIds[1];
      period = activeIds[0];
      cur = activeIds[2];
      price = product[region][cur][period];
      currency = product[region][cur]["currency"];

      const displayText = document.getElementById("displayCost");
      displayText.textContent = price + ' ' + currency;
    }

  }
}

</script>

<style scoped>
#month_1, #month_3, #month_6, #month_12, #ru, #eu, #usd, #eur {
    padding: 10px 20px;
    border: none;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

#month_1.active, #month_3.active, #month_6.active, #month_12.active, #ru.active, #eu.active, #usd.active, #eur.active {
    background-color: white;
    color: black;
}

.hidden {
    display: none;
}
</style>

