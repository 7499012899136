<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="text-h5 font-weight-medium mb-2">Наименование товара</div>
      </v-col>
    </v-row>

    <v-card elevation="0" rounded="lg" color="#474747" variant="flat" class="mt-3">
      <v-card  elevation="0" rounded="lg" class="rounded-b-0" variant="flat" color="#474747">
        <v-row class="pt-3 pb-3" align="center">
          <v-col cols="1"></v-col>
          <v-col cols="auto">Доступ в закрытый канал</v-col>
        </v-row>
      </v-card>
    </v-card>

    <v-card id="elementToHide" elevation="0" rounded="lg" color="#474747" variant="flat" class="mt-3">
      <v-card elevation="0" rounded="lg" class="rounded-b-0" variant="flat" color="#474747">
        <v-row class="pt-3 pb-3" align="center">
          <v-col cols="1"></v-col>
          <v-col cols="auto">
            <v-card width="25px" variant="tonal" height="25px" color="green" rounded="lg">
              <v-icon class="icon-pending" color="yellow">
                mdi-message-text
              </v-icon>
            </v-card>
          </v-col>
          <v-col cols="auto">Ежемесячное списание каждые 30 дн.</v-col>
        </v-row>
      </v-card>
    </v-card>

    <v-row><v-col cols="12"></v-col></v-row>
    <v-row>
      <v-col cols="12">
        <div class="text-h6 font-weight-medium mb-2">Ваш E-mail</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-text-field id="myEmail" rounded="lg" color="#474747" label="example@gmail.com"></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div class="text-h6 font-weight-medium mb-2">Период оплаты</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="3">
        <v-btn id="month_1" class="btn active" @click="activatePeriodButton('month_1')" variant="tonal">1 мес</v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn id="month_3" class="btn" @click="activatePeriodButton('month_3')" variant="tonal">3 мес</v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn id="month_6" class="btn" @click="activatePeriodButton('month_6')" variant="tonal">6 мес</v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn id="month_12" class="btn" @click="activatePeriodButton('month_12')" variant="tonal">1 год</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div class="text-h6 font-weight-medium mb-2">Метод оплаты</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4">
        <v-btn id="ru" class="btn active" @click="activateBankButton('ru')" variant="tonal">Банк РФ</v-btn>
      </v-col>
      <v-col cols="8">
        <v-btn id="eu" class="btn" @click="activateBankButton('eu')" variant="tonal">Иностранный банк</v-btn>
      </v-col>
    </v-row>

    <v-row id="TitleToHide" class="hidden">
      <v-col cols="12">
        <div class="text-h6 font-weight-medium mb-2">Валюта</div>
      </v-col>
    </v-row>

    <v-row id="CurrencyToHide" class="hidden">
      <v-col cols="4">
        <v-btn id="usd" class="btn active" @click="activateCurrencyButton('usd')" variant="tonal">USD</v-btn>
      </v-col>
      <v-col cols="4">
        <v-btn id="eur" class="btn" @click="activateCurrencyButton('eur')" variant="tonal">EUR</v-btn>
      </v-col>
    </v-row>



    <v-row>
      <v-col cols="12">
        <div class="text-h6 font-weight-medium mb-2">Стоимость</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div id="displayCost" class="text-h6 font-weight-medium mb-2">1700 RUB</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-btn id="paymentButton" class="" @click="confirmButton()" block rounded="lg">Оплатить</v-btn>
        <div id="waitingText" class="text-h6 font-weight-medium mb-2 hidden">Ожидайте...</div>
      </v-col>
    </v-row>


  </v-container>
</template>






















<script>
import axios from "axios";

var price = 1700;
var currency = 'RUB';
var region;
var period = 'month_1';
var cur;
var email;
var user_id;
var payment_method = 'BIZON'

var product = {
      'ru': {
        'usd': {
          'month_1': '1700',
          'month_3': '4500',
          'month_6': '8200',
          'month_12': '10100',
          'currency': 'RUB',
          'payment_method': 'BIZON'
        },
        'eur': {
          'month_1': '1700',
          'month_3': '4500',
          'month_6': '8200',
          'month_12': '10100',
          'currency': 'RUB',
          'payment_method': 'BIZON'
        },
      },
      'eu': {
        'usd': {
          'month_1': '18',
          'month_3': '48',
          'month_6': '88',
          'month_12': '108',
          'currency': 'USD',
          'payment_method': 'LAVA'
        },
        'eur': {
          'month_1': '16',
          'month_3': '43',
          'month_6': '79',
          'month_12': '97',
          'currency': 'EUR',
          'payment_method': 'LAVA'
        }
      }
    };

var example = {'resp': null};



export default {

  data: () => ({
    name: "TransferPage",
    showContact: false,
    recipient: "Мария",
    isActive: true
  }),


  mounted() {
    window.Telegram.WebApp.setBackgroundColor("#000000")
    window.Telegram.WebApp.onEvent('backButtonClicked', () => {
      this.$router.push({ path: '/'});
    })
    window.Telegram.WebApp.BackButton.show()

    example["resp"] = window.Telegram.WebApp.initData
    let initDataURLSP = new URLSearchParams(example["resp"]);
    var user = JSON.parse(initDataURLSP.get('user'))
    user_id = user["id"];
  },
    unmounted() {
      window.Telegram.WebApp.BackButton.hide()
  },

  methods: {
    checkInitData(data) {
      axios.post('api/checkInitData', data)
          .then(response => {
            alert(JSON.stringify(response.data));
            this.user_id = response.data.user.id
            user_id = this.user_id
          })
          .catch(error => {
            //console.error(error);
            alert(JSON.stringify(error));
          });
    },
    confirmButton() {
      const inputElement = document.getElementById('myEmail');
      email = inputElement.value;
      if (email == "") {
        alert('Укажите электронную почту');
      } else {

        const paymentButton = document.getElementById("paymentButton");
        const paymentText = document.getElementById("waitingText");
        paymentButton.classList.add("hidden");
        paymentText.classList.remove("hidden");
        //paymentButton.textContent = 'Ожидайте...';


        //user_id = 624152144;
        let my_data = {user_id: String(user_id), cost: String(price), age: period, email: email, currency: currency, payment_method: payment_method}; //{'act': 'subscribe', 'cost': String(price), 'age': period, 'user_id': String(user_id), 'email': email, 'currency': currency}

        //axios.get('api/createPayment?act=subscribe&cost='+String(price)+'&age='+period+'&user_id='+String(user_id)+'&email='+email+'&currency='+currency) // axios.get('api/createPayment?', config)    624152144 this.user_id
        axios.post('api/createPayment', my_data)
            .then(response => {
              //alert(JSON.stringify(response));
              //alert(JSON.stringify(window.Telegram.WebApp.initData)); //response.data)
              window.location.replace(response.data.url);
              //this.$router.push(response.data.url);// /transfer?id='+response.data.id'
            })
            .catch(error => {
              //alert(JSON.stringify(error));
              console.error(JSON.stringify(error));
            });
      }
    },

    // стили моего шаблона
    activatePeriodButton(activeButtonId) {
      // Получаем кнопки по id
      const button1 = document.getElementById('month_1');
      const button2 = document.getElementById('month_3');
      const button3 = document.getElementById('month_6');
      const button4 = document.getElementById('month_12');
      const elementToHide = document.getElementById("elementToHide");
      const TitleToHide = document.getElementById("TitleToHide");

      // Убираем класс active у всех кнопок
      button1.classList.remove('active');
      button2.classList.remove('active');
      button3.classList.remove('active');
      button4.classList.remove('active');

      // Добавляем класс active для нажатой кнопки
      document.getElementById(activeButtonId).classList.add('active');

      if (button2.classList.contains("active") || button3.classList.contains("active") || button4.classList.contains("active")) {
          // Если элемент имеет класс active, скрыть другой элемент
          elementToHide.classList.add("hidden");
          TitleToHide.classList.add("hidden");
      } else {
          // Показать элемент, если класса active нет
          elementToHide.classList.remove("hidden");
          TitleToHide.classList.remove("hidden");
      }



      // Находим все элементы с классом 'active'
      const activeElements = document.querySelectorAll('.active');
      // Собираем их id
      const activeIds = Array.from(activeElements).map(element => element.id);
      //alert(activeIds[0]);
      region = activeIds[1];
      period = activeIds[0];
      cur = activeIds[2];
      price = product[region][cur][period];
      currency = product[region][cur]["currency"];
      payment_method = product[region][cur]["payment_method"];

      const displayText = document.getElementById("displayCost");
      displayText.textContent = price + ' ' + currency;
    },

    activateBankButton(activeButtonId) {
      // Получаем кнопки по id
      const button1 = document.getElementById('ru');
      const button2 = document.getElementById('eu');
      const elementToHide = document.getElementById("CurrencyToHide");
      const TitleToHide = document.getElementById("TitleToHide");

      // Убираем класс active у всех кнопок
      button1.classList.remove('active');
      button2.classList.remove('active');

      // Добавляем класс active для нажатой кнопки
      document.getElementById(activeButtonId).classList.add('active');

      if (button1.classList.contains("active")) {
          // Если элемент имеет класс active, скрыть другой элемент
          elementToHide.classList.add("hidden");
          TitleToHide.classList.add("hidden");
      } else {
          // Показать элемент, если класса active нет
          elementToHide.classList.remove("hidden");
          TitleToHide.classList.remove("hidden");
      }



      // [ ]Находим все элементы с классом 'active'
      const activeElements = document.querySelectorAll('.active');
      // Собираем их id
      const activeIds = Array.from(activeElements).map(element => element.id);
      //alert(activeIds[0]);
      region = activeIds[1];
      period = activeIds[0];
      cur = activeIds[2];
      price = product[region][cur][period];
      currency = product[region][cur]["currency"];
      payment_method = product[region][cur]["payment_method"];

      const displayText = document.getElementById("displayCost");
      displayText.textContent = price + ' ' + currency;

    },

    activateCurrencyButton(activeButtonId) {
      // Получаем кнопки по id
      const button1 = document.getElementById('usd');
      const button2 = document.getElementById('eur');

      // Убираем класс active у всех кнопок
      button1.classList.remove('active');
      button2.classList.remove('active');

      // Добавляем класс active для нажатой кнопки
      document.getElementById(activeButtonId).classList.add('active');



      // [ ]Находим все элементы с классом 'active'
      const activeElements = document.querySelectorAll('.active');
      // Собираем их id
      const activeIds = Array.from(activeElements).map(element => element.id);
      //alert(activeIds[0]);
      region = activeIds[1];
      period = activeIds[0];
      cur = activeIds[2];
      price = product[region][cur][period];
      currency = product[region][cur]["currency"];
      payment_method = product[region][cur]["payment_method"];

      const displayText = document.getElementById("displayCost");
      displayText.textContent = price + ' ' + currency;
    }

  }
}

</script>

<style scoped>
#month_1, #month_3, #month_6, #month_12, #ru, #eu, #usd, #eur {
    padding: 10px 20px;
    border: none;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

#month_1.active, #month_3.active, #month_6.active, #month_12.active, #ru.active, #eu.active, #usd.active, #eur.active {
    background-color: white;
    color: black;
}

.hidden {
    display: none;
}
</style>



